import {
  ApiOutlined,
  FileDoneOutlined,
  GithubOutlined,
  MailOutlined,
  SlackOutlined,
  SyncOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Feature } from '@shared/features';
import { BedrockIcon } from '@web/bedrock/BedrockIcon';
import { useFeature } from '@web/common/useFeature';
import { TabLink, Tabs } from '@web/components/Tabs';
import { Row } from '@web/components/layout';
import { Text } from '@web/components/typography';
import * as React from 'react';

export const AdminTabs: React.FC = () => {
  const { booleanValue: githubIntegrationEnabled } = useFeature(
    Feature.GITHUB_INTEGRATION,
  );
  const { booleanValue: slackIntegrationEnabled } = useFeature(Feature.SLACK);
  const { booleanValue: surveysEnabled } = useFeature(Feature.SURVEYS);
  const { booleanValue: reviewCyclesEnabled } = useFeature(
    Feature.REVIEW_CYCLES,
  );
  const { booleanValue: alignmentIndicatorsEnabled } = useFeature(
    Feature.ENABLE_ALIGNMENT_INDICATORS,
  );
  const { booleanValue: reflectionsEnabled } = useFeature(Feature.REFLECTIONS);
  const { booleanValue: messagesEnabled } = useFeature(
    Feature.MESSAGES_ENABLED,
  );
  const { booleanValue: bedrockEnabled } = useFeature(Feature.ENABLE_BEDROCK);

  return (
    <Tabs>
      <TabLink to="/admin/users">
        <Row gap={6}>
          <UserOutlined />
          <Text style={{ lineHeight: '14px' }}>Users</Text>
        </Row>
      </TabLink>
      <TabLink to="/admin/teams">
        <Row gap={6}>
          <TeamOutlined />
          <Text style={{ lineHeight: '14px' }}>Teams</Text>
        </Row>
      </TabLink>
      {alignmentIndicatorsEnabled && (
        <TabLink to="/admin/alignment">
          <Row gap={6}>
            <ApiOutlined />
            <Text style={{ lineHeight: '14px' }}>Alignment</Text>
          </Row>
        </TabLink>
      )}
      {reviewCyclesEnabled && (
        <TabLink to="/admin/review-cycles">
          <Row gap={6}>
            <SyncOutlined />
            <Text style={{ lineHeight: '14px' }}>Cycles</Text>
          </Row>
        </TabLink>
      )}
      {reflectionsEnabled && (
        <TabLink to="/admin/reflections">
          <Row gap={6}>
            <SyncOutlined />
            <Text style={{ lineHeight: '14px' }}>Reflections</Text>
          </Row>
        </TabLink>
      )}
      {slackIntegrationEnabled && (
        <TabLink to="/admin/slack">
          <Row gap={6}>
            <SlackOutlined />
            <Text style={{ lineHeight: '14px' }}>Slack</Text>
          </Row>
        </TabLink>
      )}
      {surveysEnabled && (
        <TabLink to="/admin/surveys">
          <Row gap={6}>
            <FileDoneOutlined />
            <Text style={{ lineHeight: '14px' }}>Surveys</Text>
          </Row>
        </TabLink>
      )}
      {messagesEnabled && (
        <TabLink to="/admin/messages">
          <Row gap={6}>
            <MailOutlined />
            <Text style={{ lineHeight: '14px' }}>Messages</Text>
          </Row>
        </TabLink>
      )}
      {githubIntegrationEnabled && (
        <TabLink to="/admin/github">
          <Row gap={6}>
            <GithubOutlined />
            <Text style={{ lineHeight: '14px' }}>Github</Text>
          </Row>
        </TabLink>
      )}
      {bedrockEnabled && (
        <TabLink to="/admin/scribe">
          <Row gap={6}>
            <BedrockIcon />
            <Text style={{ lineHeight: '14px' }}>Scribe AI</Text>
          </Row>
        </TabLink>
      )}
    </Tabs>
  );
};
